import type { NinjaFormQuery, PageQuery } from '~/types';

export async function useGQLFetch<T>(query: string) {
  const config = useRuntimeConfig();
  return await useFetch<T, Error, string, 'POST'>(`${config.public.wordpressEndpoint}/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
    }),
    key: query,
  });
}

export async function useNinjaFormGetFields(formId: number) {
  const config = useRuntimeConfig();
  return await useFetch<NinjaFormQuery>(`${config.public.wordpressEndpoint}/wp-json/nf-submissions/v1/form/${formId}/fields`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'NF-REST-Key': config.public.ninjaFormKey,
    },
  });
};

interface NinjaFormSubmissionData {
  data: Array<{
    id: number;
    value: any;
  }>;
  id: number;
}

export async function useNinjaFormSubmission(formData: NinjaFormSubmissionData) {
  const config = useRuntimeConfig();
  return await useFetch<{ submissionID: string; success: string }>(`${config.public.wordpressEndpoint}/wp-json/nf-submissions/v1/submit`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'NF-REST-Key': config.public.ninjaFormKey,
    },
    body: formData,
  });
};

function isValidPreviewLink(query: any) {
  return (query.page_id || query.preview_id) && query.preview === 'true';
}

export async function usePageFetch(path: string, query: any) {
  const params = path.split('/').slice(1);
  const isPreview = isValidPreviewLink(query);

  const pageTypes = {
    article: 'postBy',
    event: 'eventBy',
  };

  const pageTypesId = {
    article: 'postId',
    event: 'eventId',
  };

  const pageType = params.length > 1 ? pageTypes[params[0] as keyof typeof pageTypes] || 'pageBy' : 'pageBy';
  const pageTypeId = params.length > 1 ? pageTypesId[params[0] as keyof typeof pageTypesId] || 'pageId' : 'pageId';

  const searchParam = isPreview ? `${pageTypeId}: ${query.page_id || query.preview_id}` : `uri: "${path}"`;

  const pageQuery = `{
  ${pageType}(${searchParam}) {
    blocksJSON
    uri
    title
    seo {
      description
      title
      openGraph {
        locale
        title
        url
        siteName
        description
      }
      robots
    }
  }
}`;

  if (isPreview) {
    const { data } = await useFetch<PageQuery>('/api/live-preview', {
      method: 'POST',
      body: JSON.stringify({
        query: pageQuery,
      }),
      key: pageQuery,
    });

    return data.value?.data[pageType];
  } else {
    const { data } = await useGQLFetch<PageQuery>(pageQuery);

    return data.value?.data[pageType];
  }
}
