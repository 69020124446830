import { defineStore } from 'pinia';

import type { ProductAttributes } from '~/types';

export interface CartItemAttributes {
  key: string;
  product: ProductAttributes;
  quantity: number;
}

export const useCartStore = defineStore('cart', {
  state: () => ({
    products: [] as Array<CartItemAttributes>,
  }),
  actions: {
    add(product: ProductAttributes, key: string) {
      const productInCart = this.getCartItemByID(key);

      if (productInCart) {
        productInCart.quantity += 1;
      } else {
        this.products.push({
          product,
          quantity: 1,
          key,
        });
      }
    },
    replace(products?: Array<CartItemAttributes>) {
      if (products) {
        this.products = products;
      }
    },
    increaseQuantity(key: string) {
      const product = this.getCartItemByID(key);
      if (product) {
        product.quantity += 1;
      }
    },
    decreaseQuantity(key: string) {
      const product = this.getCartItemByID(key);

      if (product && product.quantity > 1) {
        product.quantity -= 1;
      } else if (product && product.quantity === 1) {
        this.products = this.products.filter((item) => item.key !== product.key);
      }
    },
    delete(key: string) {
      this.products = this.products.filter((item) => item.key !== key);
    },
  },
  getters: {
    getCartItemByID(state) {
      return (key: string) => state.products.find((item) => item.key === key);
    },
  },
});
