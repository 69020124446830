import * as Sentry from '@sentry/nuxt';

import type { CartQuery, CheckoutDataForm, CheckoutMutation } from '~/types';

export const SESSION_TOKEN_KEY = 'woo-client-session';

function getSessionToken() {
  return localStorage.getItem(SESSION_TOKEN_KEY);
}

function saveSessionToken(token: string) {
  localStorage.setItem(SESSION_TOKEN_KEY, token);
}

export async function useShopFetch<T>(query: string) {
  try {
    const config = useRuntimeConfig();
    const sessionToken = getSessionToken();

    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    };

    if (sessionToken) {
      headers['woocommerce-session'] = `Session ${sessionToken}`;
    }

    return await $fetch<T>(`${config.public.wordpressEndpoint}/graphql`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        query,
      }),
      onResponse(data) {
        const newToken = data.response.headers.get('woocommerce-session');

        if (newToken) {
          saveSessionToken(newToken);
        }
      },
    });
  } catch (err) {
    Sentry.captureException(err);
    console.error(err);
  }
}

export async function useShopSession() {
  try {
    const sessionToken = getSessionToken();
    const cartStore = useCartStore();

    if (sessionToken) {
      const data = await useShopFetch<CartQuery>(`{
      cart {
        contents {
          nodes {
            key
            product {
              node {
                id
                name
                description(format: RAW)
                image {
                  sourceUrl
                }
                databaseId
                ... on SimpleProduct {
                  price(format: RAW)
                  downloadable
                  downloads {
                    file
                    name
                  }
                  type
                  uri
                }
                ... on ExternalProduct {
                  price(format: RAW)
                  externalUrl
                  type
                  uri
                }
              }
            }
            quantity
          }
        }
      }
    }`);

      if (data?.data?.cart?.contents?.nodes) {
        const extractedCartData = data.data.cart.contents.nodes.map((item) => {
          return {
            product: item.product.node,
            quantity: item.quantity,
            key: item.key,
          };
        });

        cartStore.replace(extractedCartData);
      }
    }
  } catch (err) {
    Sentry.captureException(err);
    console.error(err);
  }
}

export function useShopCheckout(data: CheckoutDataForm) {
  return useShopFetch<CheckoutMutation>(`mutation Checkout {
    checkout(
      input: {
        paymentMethod: "${data.paymentMethod.id}",
        billing: {
          address1: "${data.address1}",
          city: "${data.city}",
          company: "${data.company}",
          country: ${data.country.cca2},
          email: "${data.email}",
          firstName: "${data.firstName}",
          lastName: "${data.lastName}",
          phone: "${data.phone_extIdd && data.phone ? `${data.phone_extIdd}${data.phone}` : ''}",
          postcode: "${data.postcode}"
        }}
    ) {
      redirect
    }
  }`);
}
